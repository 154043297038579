

//// Get the element with the class .product-gallery-list
//var productGalleryList = document.querySelector(".product-gallery-list");
//
//// Get the element with the class .product-gallery
//var productGallery = document.querySelector(".product-gallery");
//
//// Add an event listener for the wheel event
//productGalleryList.addEventListener("wheel", function(event) {
//  // Prevent the default vertical scrolling behavior
//  event.preventDefault();
//  // Scroll horizontally by the amount of vertical scrolling
//  this.scrollLeft += event.deltaY;
//  // Check if the horizontal scroll is at the end
//  if (this.scrollLeft + this.clientWidth >= this.scrollWidth) {
//    // Enable vertical scrolling on the parent element
//    productGalleryList.style.overflowY = "auto";
//  } else {
//    // Disable vertical scrolling on the parent element
//    productGalleryList.style.overflowY = "hidden";
//  }
//});
//
//// Get the list element
//let list = document.querySelector(".product-gallery-list");
//
//// Get the list items
//let items = list.querySelectorAll(".gallery-items     ");
//
//// Get the first and last list items
//let firstItem = items[0];
//let lastItem = items[items.length - 1];
//
//// Add a scroll event listener to the list
//list.addEventListener("scroll", function () {
//  // Get the scroll position of the list
//  let scrollLeft = list.scrollLeft;
//
//  // If the scroll position is at the start of the list, disable horizontal scrolling
//  if (scrollLeft <= 0) {
//    list.style.overflowX = "hidden";
//  }
//
//  // If the scroll position is not at the start of the list, enable horizontal scrolling
//  if (scrollLeft > 0) {
//    list.style.overflowX = "auto";
//  }
//
//  // If the last item is fully visible, enable vertical scrolling
//  if (lastItem.offsetLeft + lastItem.offsetWidth <= scrollLeft + list.offsetWidth) {
//    list.style.overflowY = "auto";
//  }
//
//  // If the last item is not fully visible, disable vertical scrolling
//  if (lastItem.offsetLeft + lastItem.offsetWidth > scrollLeft + list.offsetWidth) {
//    list.style.overflowY = "hidden";
//  }
//});
//
//// Add a wheel event listener to the window
//window.addEventListener("wheel", function (event) {
//  // Get the delta value of the wheel event
//  let delta = event.deltaY;
//
//  // If the delta is positive, scroll the list to the right
//  if (delta > 0) {
//    list.scrollLeft += delta;
//  }
//
//  // If the delta is negative, scroll the list to the left
//  if (delta < 0) {
//    list.scrollLeft += delta;
//  }
//
//  // Prevent the default vertical scrolling behavior
//  event.preventDefault();
//});
//

//
//// Select the .product-gallery-list element
//var artistSlides = document.querySelector(".product-gallery-list");
//// Get the width of the element in pixels
//var listWidth = artistSlides.offsetWidth / 2  ;
//// Log the value of listWidth to the console
//console.log("listWidth: " + listWidth);
//
//// Listen for the wheel event on the element
//artistSlides.addEventListener("wheel", function(event) {
//  // Stop the default vertical scrolling action
//  event.preventDefault();
//  // Scroll horizontally by the amount of vertical scrolling
//  this.scrollLeft += event.deltaY;
//  // Log the value of this.scrollLeft to the console
//  console.log("this.scrollLeft: " + this.scrollLeft);
//  // If the scroll position reaches the width of the element
//  if (this.scrollLeft > listWidth) {
//    // Disable the scrolling behavior of the element
////    this.style.overflow = "hidden";
//    this.classList.add('disable-scroll');
//    // Log the value of this.style.overflow to the console
//    console.log("this.style.overflow: " + this.style.overflow);
//  }
//  // Else if the scroll position is less than or equal to zero
//  else if (window.pageYOffset <= 0) {
//    // Remove the disable-scroll class from the element
//    
//    this.classList.remove('disable-scroll');
//    // Log a message to the console
//    console.log("Scrolling enabled");
//  }
//});




jQuery(document).ready(function ($) {
   // get the p.price value
  var price = $("p.price").html();
 
  // append it to the form.cart button
  $("form.cart button").append("<span>" + price + "</span>");
  
 
  
  
  $('#menu-toggle').click(function(){
    $(this).toggleClass('open');
    $('.menus').toggleClass('open');
    
    
    
    
    
    
    
    

  });
  
//  
//  $(function() {
//  // Получаем текст из блока .copyhere
//  var text = $(document).find("#shipping_nova_poshta_for_woocommerce_city_field").html();
//
//  // Вставляем текст в блок .there
//  $(document).find(".woocommerce-shipping-fields__field-wrapper").append(text);
//  });
//  
//  
//  $(function() {
//  // Ищем div с атрибутом data-step-id="shipping" и data-step-current.
//  var div = $(".fc-checkout-step[data-step-id='shipping'][data-step-current]");
//
//  // Если такой div найден, мы добавляем ему класс gototop.
//  if (div.length) {
//    $(".fc-sidebar").addClass("gototop");
//  }
//});
  
  $(function() {
   // Получаем список элементов .zen-ui-select__option
  const options = $('.zen-ui-select__option');

  // Добавляем обработчик события клика
  options.on('click', function() {
    // Получаем значение выбранного элемента
    const value = $(this).text().trim();

    // Устанавливаем значение элемента input
    $('#shipping_novaposhta_city').val(value);
  });
  });
  
  
  
  $(function() {

  // Select the list element with the data attribute
    var VariationColor = $ ("ul[data-attribute_name='attribute_pa_color']");
    var VariationSize = $ ("ul[data-attribute_name='attribute_pa_size']");

  // Trigger a click event on the first li element
  VariationColor.find("li:first").click();
  
  VariationSize.find("li:first").click();
  });
  
  
  
  
  

//// Select the elements
//var $gallery = $(".additional-gallery");
//var $slides = $gallery.find("li");
//var $currentSlide = $slides.first();
//if ($slides.length) {
//    $gallery.removeClass("d-none");
//  }
//// Hide all slides except the first one
//});

  
  
  /**
* By Alvaro Trigo 
* Follow me on Twitter: https://twitter.com/imac2
*/


  
//  
// // Get the image and modal elements
//var image = $(".product-gallery-list img");
//var modal = $("#modal");
//var modalImage = $("#modal-image");
//
//// When the user clicks on the image, open the modal and display the image
//image.click(function() {
//  modal.show();
//  modalImage.attr("src", $(this).attr("src"));
//  modalImage.attr("alt", $(this).attr("alt"));
//});
//
//// When the user clicks on the modal image, close the modal
//modal.click(function() {
//  modal.hide();
//});
  
  

   $('input[name="wcus_np_billing_city_name"]').on('blur', function() {
        $('#shipping_novaposhta_city').val($(this).val());
    });
  
  
 $('.search-open').on('click', function() {
  $(this).next().toggleClass('open');
});
  
  
  
  
 
  
  
  
  
  // Получите изображение и элементы модального окна
var images = $(".product-gallery-list img");
var modal = $("#modal");
var modalImage = $("#modal-image");
var modalClose = $("#close-modal");
var currentIndex = 0;

// Когда пользователь нажимает на изображение, откройте модальное окно и отобразите изображение
images.click(function() {
  if (window.innerWidth > 600) {
    modal.show();
    modalImage.attr("src", $(this).attr("src"));
    modalImage.attr("alt", $(this).attr("alt"));
    currentIndex = images.index(this);
  }
});

// Когда пользователь нажимает на изображение модального окна, закройте модальное окно
modalImage.click(function() {
  modal.hide();
});
  modalClose.click(function() {
  modal.hide();
});

// Кнопки "Следующий" и "Предыдущий"
$("#next").click(function() {
  currentIndex = (currentIndex + 1) % images.length;
  changeImage();
});

$("#prev").click(function() {
  currentIndex = (currentIndex - 1 + images.length) % images.length;
  changeImage();
});

// Измените изображение в модальном окне
function changeImage() {
  var img = $(images[currentIndex]);
  modalImage.attr("src", img.attr("src"));
  modalImage.attr("alt", img.attr("alt"));
}

// Обработчики событий клавиатуры
$(document).keydown(function(e) {
  switch(e.which) {
    case 37: // left arrow key
      $("#prev").click();
      break;
    case 39: // right arrow key
      $("#next").click();
      break;
    default: return;
  }
  e.preventDefault();
});

  // Подключите Hammer.js
var hammer = new Hammer(document.body);

// Обработчики событий свайпа
hammer.on("swipeleft", function() {
  $("#next").click();
});

hammer.on("swiperight", function() {
  $("#prev").click();
});
  
  



});
//
//document.addEventListener('DOMContentLoaded', () => {
//// Используя цикл while()
//while (!document.querySelector('input[name="wcus_np_billing_city_name"]')) {
//  // Ждем, пока поле появится
//}
//
//const billingCityInput = document.querySelector('input[name="wcus_np_billing_city_name"]');
//const shippingCityInput = document.querySelector('input[name="shipping_novaposhta_city"]');
//
//billingCityInput.addEventListener('input', () => {
//  shippingCityInput.value = billingCityInput.value;
//});
//});


//
// jQuery (function ($) {
//  $ (document.body).on ('change', 'select [name=\"calc_shipping_country\"]', function () {
//  $ (this).submit ();
//  });
//  });



//
//const postScroll = document.querySelector(".product-gallery-list");
//let scrollingHorizontally = true;
//
//postScroll.addEventListener("wheel", (event) => {
//  if (scrollingHorizontally) {
//    postScroll.scrollBy({
//      left: event.deltaY < 0 ? -45 : 45,
//    });
//    event.preventDefault();
//
//    // check if the user has reached the end of the slider
//    if (postScroll.scrollLeft >= postScroll.scrollWidth - postScroll.clientWidth) {
//      scrollingHorizontally = false;
//      postScroll.style.overflowY = "auto";
//    }
//  }  else if (postScroll.scrollTop === 0) {
//    // reset the scrollLeft to zero
////    postScroll.scrollLeft = 0;
//    // enable horizontal scrolling again
//    scrollingHorizontally = true;
//    // disable vertical scrolling
//    postScroll.style.overflowY = "hidden";
//    // prevent default behavior
//    
//  } 
//  
//  
//  
//  else {
//    // (scroll the page up/down)
//    return true;
//  }
//});
//


//
//(function(){
//    init();
//
//    var g_containerInViewport;
//    function init(){
//        setStickyContainersSize();
//        bindEvents();
//    }
//
//    function bindEvents(){
//        window.addEventListener("wheel", wheelHandler);        
//    }
//
//    function setStickyContainersSize(){
//        document.querySelectorAll('.product-gallery').forEach(function(container){
//            const stikyContainerHeight = container.querySelector('.product-gallery-list').scrollWidth;
//            container.setAttribute('style', 'height: ' + stikyContainerHeight + 'px');
//        });
//    }
//
//    function isElementInViewport (el) {
//        const rect = el.getBoundingClientRect();
//        return rect.top <= 0 && rect.bottom > document.documentElement.clientHeight;
//    }
//
//    function wheelHandler(evt){
//        
//        const containerInViewPort = Array.from(document.querySelectorAll('.product-gallery')).filter(function(container){
//            return isElementInViewport(container);
//        })[0];
//
//        if(!containerInViewPort){
//            return;
//        }
//
//        var isPlaceHolderBelowTop = containerInViewPort.offsetTop < document.documentElement.scrollTop;
//        var isPlaceHolderBelowBottom = containerInViewPort.offsetTop + containerInViewPort.offsetHeight > document.documentElement.scrollTop;
//        let g_canScrollHorizontally = isPlaceHolderBelowTop && isPlaceHolderBelowBottom;
//
//        if(g_canScrollHorizontally){
//            containerInViewPort.querySelector('.product-gallery-list').scrollLeft += evt.deltaY;
//        }
//    }
//})();
//
